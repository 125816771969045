import React from 'react';
import { Button } from '@codeparva/ui-components-library';
import { FontAwesomeIcon } from '@codeparva/gatsby-theme-layout-components/src/utilities/iconLoader/FontAwesomeIcon';
// import { weakKey } from '../../../../../../utilities/weakKey';
import { JumbotronTypes } from '@codeparva/gatsby-theme-layout-components';
import { useJumbotronContext } from '@codeparva/gatsby-theme-layout-components/src/sections/homePage/components/jumbotron/context/jumbotronContext';

export const JumbotronTitleAndLocationSelectSingleLocation = ({
  contactDetails,
  isImage,
}: JumbotronTypes.TJumbotronTitleLocationSingle) => {
  const jumbotronContext = useJumbotronContext();
  const logOutHandler = () => {
    const customUrl = 'https://g.page/r/CW_yCXK6qBkAEBM/review';
    window.open(customUrl, '_blank');
  };
  return (
    <div className="pageContentTitle is-flex is-align-items-center is-flex-direction-column">
      <h1>{contactDetails[0].facilityName}</h1>
      <p className="subtitle1">{jumbotronContext.jumbotron_subtitle.description}</p>
      <div>
        <a
          href={contactDetails[0]?.address && contactDetails[0]?.address[0]?.link}
          className={`is-flex is-align-items-center ${
            isImage ? 'has-text-secondary' : 'has-text-primary'
          }`}
          target="_blank"
        >
          <FontAwesomeIcon
            iconClassName={
              (contactDetails[0]?.address &&
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                contactDetails[0]?.address[0]?.icon?.iconClassName!) ||
              'FaMapMarker'
            }
            iconContextProps={{
              className: `${isImage ? 'has-text-secondary' : 'has-text-primary'} pr-3`,
              style: {
                fontSize:
                  (contactDetails[0]?.address && contactDetails[0]?.address[0]?.icon?.size) || 28,
              },
            }}
          />
          {contactDetails[0]?.address && contactDetails[0]?.address[0].data}
        </a>
      </div>
      <br />
      <Button
        // className="cp-section-bg-dark"
        color="primary"
        size="large"
        onClick={logOutHandler}
      >
        Leave us review here!
      </Button>
    </div>
  );
};

export { JumbotronTitleAndLocationSelectMultiLocation } from '@codeparva/gatsby-theme-layout-components/src/sections/homePage/components/jumbotron/components/background/BackgroundHelper';
